import React, { useState } from "react";
import firebase from "../../firebase";
import { Navbar, Nav, Container, NavDropdown, Row, Col } from "react-bootstrap";
import logo from "../../assets/logo-new.png";
import { useNavigate } from "react-router-dom";

const Navbars = (props) => {
  const [left1, setLeft1] = useState(0);
  const { active } = props;
  const navigate = useNavigate();

  const Logout = () => {
    firebase
      .auth()
      .signOut()
      .then(function () {
        navigate("/Admin_login");
      });
  };

  return (
    <div>
      <Navbar bg="white" variant="light" expand="lg" style={{ zIndex: 4 }}>
        <Container>
          <Navbar.Brand href="#home">
            <img src={logo} style={{ width: 150 }} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />

          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto navbar-transparent" activeKey={active}>
              <Nav.Link eventKey="link-1" href="/Add_blogs">
                BLOGS
              </Nav.Link>

              <Nav.Link>
                <button className="btn btn-danger" onClick={() => Logout()}>
                  Logout
                </button>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default Navbars;
