import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./sec1.css";
import S4 from "../../assets/who2.png";
import Zoom from "react-reveal/Zoom";
import Fade from "react-reveal/Fade";
import ManageImg from "../../assets/manage.png";
const AboutUs = () => {
  return (
    <div className="container-fluid mt-5 abtmain mb-5" id="about">
      <Container fluid className="abtsec">
        <Row className="mt-5">
          <Col></Col>
         

          <Col md={5} lg={5} xs={12}>
            <center>
              <div className="pb-2">
                <Fade left>
                  <img src={S4} className="abtwall" />
                </Fade>
              </div>
            </center>
          </Col>
          <Col md={6} lg={6} xs={12}>
            <Zoom>
              <div className="seca text-center">
                <center>
                  <h1>
                    <b><span className="grey-color">W</span>HO <span className="grey-color">W</span>E <span className="grey-color">A</span>RE</b>
                    <hr />
                  </h1>
                  <br />{" "}
                  <p className="justify-center">
                    Leading firm of Chartered Accountants and business advisors
                    with its corporate office situated at Tirur, Kerala, India,
                    offering broad range of services in assurance, audit,
                    advisory, consulting, compliance, tax and legal domains in
                    india and overseas by foreign collabration initiative and
                    other international tie-ups.
                  </p>
                  
                </center>
              </div>
            </Zoom>
          </Col>
          <Col></Col>
        </Row>
        <Fade up>
          <div className="acard">
            <Row>
              <Col></Col>
              
              <Col md={6} lg={6} xs={12}>
                <div className="a-con">
                  <h5 className=" text-center mt-1">
                    <b>
                      Managed by the best experienced chartered accountants with
                      other Tax and finance professionals in India
                    </b>
                  </h5>
                  <hr />
                  <p className="justify-center mt-3">
                    We are a group of adept professionals well-versed in
                    providing sound solutions to every need related to accounts,
                    finance, and tax issues promptly and professionally. Firm is
                    equipped with optimum infrastructure with latest state of
                    the art technology for research-oriented quality and
                    maintain professionalism and strive for excellence in all
                    our activities and achieve an exceptional growth.
                  </p>
                <p className="justify-center mt-3">
                Understanding that every existing business is different from one another, our approach is flexible and tailored as per the client’s needs. The timely professional services which we provide is complemented by the quality-oriented with a customer centric approach, Bizcare is a congregation of professionally qualified and experienced personnel who are capable of providing sound business guidance to clients. Our team of chartered accountants abides by a code of conduct and have vast knowledge, professional experience and specialized skills to serve the clients better by providing sound financial advice as well as solutions to them
                </p>
                </div>
              </Col>
              <Col md={4} lg={4} xs={12}>
                <Zoom down>
                  <img src={ManageImg} className="ac-img" />
                </Zoom>
              </Col>
              <Col></Col>
            </Row>
          </div>
        </Fade>
      </Container>
    </div>
  );
};

export default AboutUs;
