import { Zoom } from "react-reveal";
import ReactWhatsapp from "react-whatsapp";
import Chat from "../../assets/whp.png";
import "./whp.css";
import PhoneIcon from "../../assets/phone.png";

const WhatsApp = () => {
  return (
    <Zoom up>
      <div className="whp">
        <a href="tel:+919995444429">
          <center>
            <img src={PhoneIcon} className="cmbn0" />
          </center>
        </a>

        <ReactWhatsapp
          number="+919995444429"
          message="Hello !"
          className="rwbn mt-2"
        >
          <img src={Chat} className="wpn" />
        </ReactWhatsapp>
      </div>
    </Zoom>
  );
};

export default WhatsApp;
