import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import FixedBar from "../../components/FixedBar";
import HomeBn from "../../components/HomeBn";
import "../../services.css";
import Img from "../../assets/services/management.jpg";
import Footer from "../../components/Footer";
import Fade from "react-reveal/Fade";
import ContactUs from "../../components/ContactUs";
import WhatsApp from "../../components/Whatsapp";

const Management = () => {
  return (
    <div className="p-2 serv">
      <FixedBar home={true} />
      <Fade>
        <img src={Img} className="w-100 rounded mb-4 svimg" />
      </Fade>
      <Container>
        <Row>
          <h2 className="svhead">MANAGEMENT ADVISORY SERVICES</h2>
        </Row>
        <i>"Our business philosophy helps you enrich your business”</i>
        <div className="sline" />
        <p className="justify">
          The way enterprises conduct business has drastically changed over the
          last few years. The scope of management consulting is vast and
          dynamic.. Assisting businesses in enhancing their performance by
          analysing the present-day complications and fabricating a strategy for
          development, It covers under its gamut miscellaneous services to be
          performed by any organization for systematic and effective functioning
          of its business operations Services of management consultancy are
          required for diversified reasons including securing external guidance
          as well as accessing consultants’ area of proficiency. The disruption
          and opportunities enabled by technology evolution is unimaginable. The
          increased competition due to lessened entry barriers, democratization
          of business models and sheer wave of start-ups and entrepreneurship is
          forcing companies to remain competitive.
          <br /> <br />
          Doing business in an era of increased competition requires enterprises
          to be cost efficient in operation, innovative in product or service
          delivery, professional in approach and organized in management. On the
          other hand, Governments and regulators have tightened compliance
          requirements across the globe seeking more visibility and disclosures
          from businesses. In nutshell, organizations, whether small or large
          are exposed to several risks such as market, legal, financial and
          operational. Assessing and taking risk mitigating measures are
          fundamental to growth and survival in an increasingly complex business
          environment.
          <br /> <br />
          Through our gamut of advisory services, we have assisted companies to
          live up to their true potential, better exploit their strengths,
          accelerate growth, improve productivity and efficiency, enter new
          markets as well as improve compliance track record.
          <br /> <br />
          We offer three advisory services that regulates every aspect of the
          organization. Finance & Compliance, Standard Operating Procedures,
          Capital Budgeting strategy.
          <br />
        </p>
        <Row>
          <Col md={6} lg={4} xs={12}>
            <div className="shadowbox p-3">
              <h5>
                <b>Finance & Compliance</b>
              </h5>
              <li>Internal Audit</li>
              <li>Accounting SOP design</li>
              <li>Organization Design</li>
              <li>Performance Audits</li>
              <li>Cost Audits</li>
              <li>Performance Management Systems</li>
              <li>Financial Advisory Services</li>
            </div>
          </Col>
          <Col md={6} lg={4} xs={12}>
            <div className="shadowbox p-3">
              <h5>
                <b>Capital Budgeting Strategy</b>
              </h5>
              <li>Investment Advisory</li>
              <li>Valuation</li>
              <li>Merger &amp; Acquisition</li>
              <li>Project &amp; feasibility Studies</li>
              <li>Market Entry Strategy</li>
              <li>Strategy Development</li>
              <li>Marketing Strategy</li>
              <li>Organizational Design</li>
              <li>Franchising</li>
            </div>
          </Col>
          <Col md={12} lg={4} xs={12}>
            <div className="shadowbox p-3">
              <h5>
                <b>SOP Designing and Implementing</b>
              </h5>
              <ul>
                <li>Analysing current SOP in operation</li>
                <li>Designing SOP - operations</li>
                <li>Monitoring and reviewing SOP</li>
                <li>Performance Management Systems report</li>
              </ul>
            </div>
          </Col>
        </Row>
        <h5 className="blue-color">
          Our expert will help you with Get a free consultation today.
        </h5>
        <br />
        <h5>FAQs </h5>
        <br />
        <b> What is management consulting?</b>
        <br />
        <br />
        <p>
          Management consulting is the practice of serving companies to improve
          their production. The scope of management consulting is vast and
          dynamic. Services of management consultancy are required for
          diversified reasons, including securing external guidance and
          accessing consultants’ areas of proficiency. Management consultancy
          approach is integrated to tackle with all the aspects of running of an
          entity viz throughout thorough planning, practicality, outsourcing,
          compliance outsourcing as well as transactional services.
          <br /> <br />
          <b> What are the stages of management consulting?</b>
          <br /> <br />
          The seven stages of management consulting are:
        </p>
        <ul>
          <li>Clarifying client expectations</li>
          <li>Briefing and building the team</li>
          <li>Preparing the thinking</li>
          <li>Conducting the analysis and managing the data collection</li>
          <li>
            Driving the synthesis, preparing communication and key presentations
          </li>
          <li>
            Coaching the client to lead the implementation and developing change
            agents
          </li>
          <li>
            Managing client expectations and closing the engagement successfully
          </li>
        </ul>
        <b>Why do companies hire management consulting firms?</b>
        <br />
        <br />
        <p className="justify">
          Management consultants are experts at making sense out of complex
          business data. They don’t take over the day-to-day decision-making in
          a business. Instead, they provide additional support to the
          decision-making process. Although the executives tend to be tied up in
          daily operations, management consultants can take a more in-depth
          approach to solve current issues. They can collect data from your
          day-to-day operations and uncover what the numbers tell. Most
          companies perform at a sub-optimal level, knowing that there is room
          to grow but not knowing how to begin. Management consultants reveal
          these shortcomings and propose improvement solutions. Thus, the role
          of consultants becomes very valuable to their customers.
        </p>
        <b>What is the problem-solving approach?</b>
        <br />
        <br />
        <p className="justify">
          A big part of management consulting involves taking a strategic
          approach to problem-solving. By prospecting all angles to a specific
          challenge, consultants can provide more valuable and practicable
          solutions to their clients. Most consultants follow five main steps
          when addressing a business problem. The steps are as follows:
        </p>
        <ul>
          <li>Defining the problem</li>
          <li>Deciding on the approach to take</li>
          <li>Collecting and analyzing data</li>
          <li>Presenting recommendations</li>
          <li>Follow up: were the recommendations successful?</li>
          <li>Natural influencer</li>
          <li>Intuitive communicator</li>
          <li>Detail-oriented</li>
          <li>Emotional intelligence</li>
        </ul>
        <b>What services do you provide under management consulting?</b>
        <br />
        <br />
        <p>
          At Bizcare, we provide the following services under management
          consulting:
        </p>
        <ul>
          <li>Business planning</li>
          <li>Transaction advisory</li>
          <li>Valuation of businesses</li>
          <li>Due diligence</li>
          <li>Feasibility assessment</li>
          <li>Internal restructuring consultancy</li>
          <li>Standard operating procedure (SOP) development</li>
          <li>Strategic planning and consultancy</li>
        </ul>
      </Container>
      <ContactUs />

      <WhatsApp />
      <Footer />
    </div>
  );
};

export default Management;
