import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import FixedBar from "../../components/FixedBar";
import HomeBn from "../../components/HomeBn";
import "../../services.css";
import Img from "../../assets/services/startup.jpg";
import Footer from "../../components/Footer";
import Fade from "react-reveal/Fade";
import ContactUs from "../../components/ContactUs";
import WhatsApp from "../../components/Whatsapp";

const Startup = () => {
  return (
    <div className="p-2 serv">
      <FixedBar home={true} />
      <Fade>
        <img src={Img} className="w-100 rounded mb-4 svimg" />
      </Fade>
      <Container>
        <Row>
          <h2 className="svhead">
            START UP / COMPANY REGISTRATION and other Related Services
          </h2>
        </Row>
        <div className="sline" />
        <ol>
          <li>COMPANY REGISTRATION </li>
          <p className="justify">
            Starting a company or starting a business  is not as facile as it
            looks. At Bizcare, we offer solutions and render assistance in
            setting up your business in India by carrying out the registration
            of the business under the respective governing laws such as The
            Companies Act 2013, The Indian Partnership Act, 1932, The Limited
            Liability Partnership Act, 2008.
          </p>
          <p>
            There are various types of business entities and we can help you
            select the most appropriate one considering your requirements.
          </p>
          <p>Below are the forms of establishing a company in India:</p>
          <ol>
            <li className="bold">Public limited company</li>
            <p className="justify">
              A public company is an entity, the ownership of which is
              disseminated to general public in the form of shares or securities
              which are freely traded on any stock exchanges or over the counter
              exchanges or can be acquired privately through Initial Public
              Offering (IPO). The model of public limited company (PLC) is
              registered by Ministry of Corporate Affairs (MCA) and provides a
              huge platform to the entrepreneur for expanding their business
              horizons nationally as well as internationally. A public company
              can raise money from general public and they have full stake and
              interest in the company. A public Limited Company is governed by
              the provisions of Companies Act, 2013.
            </p>
            <li className="bold">Private limited company</li>
            <p className="justify">
              One of the most popular legal structures for incorporating a
              company in India is Private Limited Company. Registering a private
              company in India should be the first and foremost priority as a
              registered company enjoys multiple benefits starting from easy
              registration to easy dissolution. Private limited company setup is
              most popular among growing companies and start-ups because of easy
              outside funding, limited liability of shareholders and offering of
              employee stock options to pull in top talent.
            </p>
            <li className="bold">One-person company (OPC)</li>
            <p className="justify">
              With the inception of Companies Act, 2013, concept of One Person
              Company (OPC) in India was originated to advocate entrepreneurs
              capable of starting a single person venture and operating in a
              corporate setup with protection of limited liability. OPC allows
              negligible interference from third party, making it a more
              beneficial as well as lucid structure as compared to other
              entities.
            </p>
            <p className="justify">
              OPC is owned, managed and controlled by a single member who is
              also the director of OPC. OPCs are one of the major sources of
              tremendously increasing overall economy of India. Numbers are
              still rising as more entrepreneurs are coming up and setting their
              own businesses. It provides opportunities to all the creative
              minds as well as infuses fresh blood and ideas in the market.
            </p>
            <li className="bold">Section 8 company </li>
            <p className="justify">
              A Section-8 Company under Companies Act, 2013 is the same as
              Section 25 Company under the old Companies Act, 1956. A Section 8
              company is defined as one which:
            </p>
            <ul>
              <li>
                has the promotion of commerce, art, science, sports, education,
                research, welfare, religion, charity, protection of environment
                or any such other object as its objects;
              </li>
              <li>
                intends to apply its profits, if any, or any other income in
                promoting its objects; and
              </li>
              <li>does not intends to pay any dividend to its members</li>
            </ul>
            <p>
              Other forms of setting up business in India are listed hereunder:
            </p>
            <li>Sole proprietorship</li>
            <p className="justify">
              Proprietorship is a form of entity which is owned, managed and
              controlled by one person, who is the sole proprietor of the firm.
              A proprietorship is known by various other names like sole
              proprietorship, individual proprietorship or sole trader. Sole
              proprietor has unlimited liability and will be accountable for all
              the profits earned as well as losses incurred by the business.
            </p>
            <p className="justify">
              Unlike other forms of business, this business setup does not offer
              the owner a host of benefits like separate legal entity,
              independent status, corporate existence, limited liability, free
              transferability or perpetual existence. Registering as
              proprietorship firm is suitable for small businesses having
              limited presence. No government fee is involved for registering
              proprietorship in India.
            </p>
            <li>Partnership Firm</li>
            <p className="justify">
              Partnership firm is owned, managed and controlled by partners
              constituting the firm. As compared to other forms of businesses,
              partnership firms are comparatively convenient to incorporate.
              Such form of doing business is prevalent amongst small as well as
              medium sized businesses in the unorganized sector. Quantum of
              resources in the form of investments is higher as compared to a
              sole proprietorship because association is between two or more
              persons. Partners in their individual capacity can contribute more
              effort, time and capital for the business.
            </p>
            <b>Types of partnership firms</b>
            <p className="justify">
              As per the provisions of Indian Partnership Act, a firm is allowed
              to be formed and executed by entering into partnership agreement.
              A partnership firm can be of the following types:
            </p>
            <b>Unregistered partnership firm:</b>
            <p className="justify">
              This form of business organization is formed and executed by
              entering into agreement by the partners of the proposed firm. It
              has legal existence in the eyes of law and allows the partners to
              carry on their business activities as per the terms of agreement.
              An unregistered partnership firm can be registered at any point of
              time.
            </p>
            <b>Registered partnership firm: </b>
            <p className="justify">
              Such firm is to be registered with the Registrar of Firm (ROF)
              having requisite jurisdiction over the place of business of the
              firm. Certain government fee is paid to ROF for registering the
              partnership firm. Such fee is fixed as per State Law and varies
              from state to state.
            </p>
            <li>Limited Liability Partnership (LLP)</li>
            <p className="justify">
              The advent of LLP lead to abrasion of incorporation of partnership
              firms in India as LLP is a hybrid of company as well as
              partnership. It exhibits characteristics of both of them. We at
              MARSOOQ & CO exhibit all steps necessary for incorporating and
              registering LLP as per the provisions of Limited Liability
              Partnership Act, 2008. Quality services are what we aim for and
              deliver the same on time.
            </p>
            <p className="justify">
              One of the crucial reasons for registering as LLP is the aspect of
              limited liability. This concept is entirely different from
              proprietorship as well as partnership where personal assets of the
              directors or partners are attached in case of bankruptcy of
              business.
            </p>
            <b>Characteristics of LLP</b>
            <br />
            <ul>
              <li>Separate legal entity:</li>
              <ul>
                <li>
                  LLP is a legal entity separate and distinct from its owners.
                  Partners can sue each other in case any such situation arises.
                </li>
                <li>
                  It has perpetual succession i.e., members may come and go but
                  the business remains.
                </li>
                <li>
                  All the members have to mutually agree on dissolution of the
                  firm by executing a term of dissolution.
                </li>
              </ul>
              <li>Flexible structure:</li>
              <ul>
                <li>
                  Ownership of LLP is easily transferable. Any person can
                  swiftly be engaged in as a designated partner and the
                  ownership shifts to them.
                </li>
              </ul>
              <li>Suitability:</li>
              <ul>
                <li>
                  Registering as LLP is beneficial for startups as well as small
                  businesses having capital below INR 25 lakhs and turnover less
                  than INR 40 lakhs per year because of non-requirement of
                  undergoing any formal audits.
                </li>
                <li>
                  LLP is recognized as an artificial juristic person hence, can
                  acquire property in its own name. Members of LLP cannot claim
                  the property acquired in the name of LLP as theirs.
                </li>
              </ul>
              <li>Management distinction:</li>
              <p className="justify">
                Partners who constitute LLP are the owners and manage the
                business of the said LLP. This is different from private limited
                company, whose directors may be different from shareholders.
                This is one of the main reasons why venture capitals resist
                investing in LLP.
              </p>
            </ul>
          </ol>
          <li>Other Registration</li>
          <ol>
            <li>Trademark Registration</li>
            <p className="justify">
              Our business philosophy helps you enrich your business
            </p>
            <p className="justify">
              Trademark is a sign, logo, name or mark, used to distinguish the
              goods or services of one person from those of others. Trademarks
              related to goods and services are administered by the Trade Marks
              Act, 1999. Trademark registration is valid for 10 years and the
              registration can be renewed every 10 years thereafter upon payment
              of renewal fee.
            </p>
            <br />
            <b>We can assist you with the following:</b>
            <ul>
              <li>
                Preliminary searching of existing trademark by
                conducting trademark searches so that applications are not
                rejected on grounds of repetition of already existing name.
              </li>
              <li>
                An application for the filing or registration of trademark so as
                to enable exclusive legal rights to the owner of the trademark.
                Simultaneously, examination of application by the Trade Mark
                Registry (TMR).
              </li>
              <li>
                Filing as well as responding to oppositions raised on the
                registration of trademark by trademarks office (TO) or any other
                party. Preparing responses for TO actions and attending
                opposition hearings whenever required.
              </li>
              <li>
                Publication of the mark in the Trademarks Journal. Rendering
                monitoring services so that marks published in the Journal can
                be monitored and protected from being copied or infringed.
              </li>
              <li>
                Handling trademark registration issues and suggesting
                corrections or amendments in the application, if any.
              </li>
              <li>
                Preparation of renewal instruction letter for trademark
                renewal and forwarding the same to TMR for updating docket
                records.
              </li>
            </ul>
            <li>FSSAI Registration Services</li>
            <p>License acquisition made effortless</p>
            <p className="justify">
              Food Safety and Standards Authority of India (FSSAI) is
              established under the Ministry of Health & Family Welfare,
              Government of India, as an autonomous body. The FSSAI is governed
              by the Food Safety and Standards Act, 2006 which regulates
              standards related to food safety and regulation in India.
            </p>
            <p className="justify">
              It is an obligation for all Food Business Operators (FBO) to
              acquire FSSAI license or food licenses. FSSAI lays down the
              standards for the articles of food. Also, the manufacturing,
              storage, distribution, sale and the import of food articles is
              regulated by FSSAI in order to ensure the availability of safe and
              wholesome food to people residing in the country. In addition to
              the above, FSSAI is also in-charge for spreading awareness and
              creating information network across the country. It ensures that
              the public in general receive rapid, reliable and accurate
              information about the food safety, hygiene and the related issues
              of importance.
            </p>
            <br />
            <b>
              We can assist you in obtaining any of the following FSSAI
              Licenses:
            </b>
            <ul>
              <li>
                <b>FSSAI Registration:</b> These are acquired by the petty food
                manufacturers and also the small-sized manufacturers,
                transporters, retailers, storage units, marketers, distributors,
                etc. FSSAI Registration is issued by the respective State
                Government, depending upon the eligibility of FBO. It is
                generally for the units having an annual turnover of up to INR
                12 lakhs. The tenure of license is for 1-5 years.
              </li>
              <li>
                <b>FSSAI Registration State License:</b> Small to medium-sized
                manufacturers, retailers, transporters, marketers, storage
                units, distributors, etc., are eligible for acquiring this
                license. It is mandatory to have operations in a single state.
                FSSAI license is issued by respective State Government.
                Generally, units having an annual turnover of more than INR 12
                lakhs are covered here. Validity of license ranges from 1-5
                years.
              </li>
              <li>
                <b>FSSAI Registration Central License:</b>Food business
                operators like importers, 100% Export Oriented Units (EOU),
                large manufacturers, operators in the Central Government
                agencies, airports, seaports etc., are required to obtain a
                Central Food License. Central Government is responsible for
                issuing the License. Furthermore, FBOs have to obtain the
                Central License for their head office, and if they have
                operations in more than one state. The units having an annual
                turnover of more than INR 20 crores are covered in this
                category. Validity of license ranges from 1-5 years.
              </li>
            </ul>
            <p>
              Further, applicability of license acquisition will depend upon the
              nature, size and type of business.
            </p>
            <li>IEC (Import Export Code)</li>
            <p className="justify">
              In this age of cut-throat competition, everyone wants to grow
              their business beyond the limits of the domestic market. However,
              doing business globally isn’t just a cup of tea for everyone.
              Before going global, you need to follow several procedures and
              laws in place and get different registration and license. IEC
              (Import Export Code) license is one of such prerequisites when
              you’re thinking of importing or exporting from India. It is also
              known as Importer- Exporter Code.
            </p>
            <p className="justify">
              IEC (Import Export Code) is required by anyone who is looking to
              kick-start his/her import/export business in the country. It is
              issued by the DGFT (Director General of Foreign Trade). IEC is a
              10-digit code that has lifetime validity. Predominantly importers
              merchants cannot import goods without the Import Export Code and
              similarly, the exporter merchant cannot avail benefits from DGFT
              for the export scheme, etc. without IEC.
            </p>
            <b>Situations Where IEC is Required</b>
            <ol className="black-list">
              <li>
                When an importer has to clear his shipments from the customs
                then it’s needed by the customs authorities.
              </li>
              <li>
                When an importer sends money abroad through banks then it’s
                needed by the bank.
              </li>
              <li>
                When an exporter has to send his shipments then it’s needed by
                the customs port.
              </li>
              <li>
                When an exporter receives money in foreign currency directly
                into his bank account then it’s required by the bank
              </li>
            </ol>
          </ol>
        </ol>
      </Container>
      <ContactUs />
      <WhatsApp />
      <Footer />
    </div>
  );
};

export default Startup;
