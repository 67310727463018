import React from "react";
import "./footer.css";
import FB from "../../assets/fb.png";
import LN from "../../assets/linked.png";
import IG from "../../assets/insta.png";
import WP from "../../assets/whp.png";
import Phone from "../../assets/phone.png";
import Pin from "../../assets/pin.png";
import Mail from "../../assets/mail.png";
import {Nav, Row, Col, Container} from "react-bootstrap";
import {FaEnvelope, FaFacebook, FaInstagram, FaLinkedin, FaPhone, FaPhoneAlt, FaPhoneSquareAlt} from 'react-icons/fa'
function Footer() {
    return (
        <div className="foot footbar pt-3 mt-3">
            <Row className=" p-3">
                <Col md={3}
                    lg={3}
                    xs={12}>
                    <center>
                        <Row>
                            <Col>
                                <a className="btn" href="https://www.facebook.com/bizcaresolution?mibextid=LQQJ4d">
                                    <FaFacebook color="#fff" size={30}/>
                                </a>
                            </Col>
                            <Col>
                                <a href="https://www.instagram.com/marsooqncohttps://instagram.com/bizcaresolution?igshid=YmMyMTA2M2Y=" className="btn">
                                <FaInstagram color="#fff" size={30}/>
                                </a>
                            </Col>
                            {/* <Col>
                                <a href="https://www.linkedin.com/company/marsooq-co/" className="btn">
                                   <FaLinkedin color="#fff" size={30}/>
                                </a>
                            </Col> */}
                           
                           
                        </Row>
                    </center>
                </Col>
                <Col md={6}
                    lg={6}
                    xs={12}>
                    <center>
                        <div>
                            <img src={Pin}
                                className="f-logo"/>
                            <p className="mt-1 text-center"
                                style={
                                    {color: "#fff"}
                            }>
                                <br/>
                                Access Mall,Near Town hall,
                                <br/>
                                Tirur, Malappuram,
                                <br/>
                                Kerala – 676101
                                <br/>
                                TIRUR
                            </p>
                        </div>
                    </center>
                </Col>
                <Col md={3}
                    lg={3}
                    xs={12}>
                    <center>
                        <Row>
                        <Col></Col>
                            <Col lg={3} xs={2}>
                                <a  href="mailto:bizcaresolution@gmail.com">
                                    <FaEnvelope color="#fff"
                                        size={30}/>
                                </a>
                            </Col>
                            <Col lg={8} xs={8}>
                                <p className="white-color mt-1">bizcaresolution@gmail.com</p>
                            </Col>
                            <Col></Col>
                        </Row>

                        <Row>
                          <Col></Col>
                            <Col lg={3} xs={2}>
                                <a href="tel:+919072444429">
                                    <FaPhoneSquareAlt color="#fff"
                                        size={30}/>
                                </a>

                            </Col>
                            <Col lg={8} xs={6}>
                                <p className="white-color mt-1">+91 9072444429</p>
                               
                            </Col>
                            <Col></Col>
                        </Row>

                        <Row>
                        <Col></Col>
                            <Col lg={3} xs={2}>
                                <a href="tel:+919995444429">
                                    <FaPhoneSquareAlt color="#fff"
                                        size={30}/>
                                </a>

                            </Col>
                            <Col lg={8} xs={6}>
                            <p className="white-color mt-1">+91 9995444429</p>
                               
                            </Col>
                            <Col></Col>
                        </Row>

                    </center>
                </Col>
            </Row>
            <div className="footbar">
                <center>
                    <p className="fttext mt-2">
                        Copyright ©2022 All rights reserved</p>
                </center>
            </div>
        </div>
    );
}

export default Footer;
