import React, { useEffect, useState } from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import FixedBar from "../../components/FixedBar";
import Footer from "../../components/Footer";
import firebase from "../../firebase";
import "./blog.css";
import parse from 'html-react-parser';

const Blog = () => {
  const [bdata, setBData] = useState(null);
  //   const items = [{ content: "check1" }, { content: "check2" }];
  const params = useParams();
  const id = params.id;

  useEffect(() => {
    fetchDatas();
  }, []);

  const fetchDatas = async () => {
    try {
      var snapshot = await firebase
        .firestore()
        .collection("blogs")
        .doc(id)
        .get();

      setBData(snapshot.data());
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <FixedBar home={true} />
      {bdata != null ? (
        <Container style={{ minHeight: "70%" }} className="bpara">
          <div className="card-blog-data">
            <img
              src={bdata.url}
              className="imagecom w-100"
              height={300}
              style={{ objectFit: "contain" }}
            />
            <h3 className="blue-color">{bdata.head}</h3>
            <div className="line mt-1" />
            {/* <p className="justify p-2">
              <pre>{bdata.content}</pre>
            </p> */}
             {parse(bdata.content)}
          </div>
        </Container>
      ) : (
        <div className="load">
          <center>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </center>
        </div>
      )}
      <Footer />
    </div>
  );
};

export default Blog;
