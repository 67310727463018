import React, { useRef, useState } from "react";
import { Col, Container, Form, Row, Spinner } from "react-bootstrap";
import "./contact.css";
import Popup from "reactjs-popup";
import Chat from "../../assets/Chat.png";
import { Zoom } from "react-reveal";
import emailjs from "@emailjs/browser";

const ContactUs = (props) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const form = useRef();

  const sendEmail = (e) => {
    setLoading(true);
    e.preventDefault();
    const data = new FormData(e.target);
    const name = data.get("name");
    const email = data.get("email");
    const message = data.get("message");
    const phone = data.get("phone");

    console.log(name);

    if (name != "" && email != "" && message != "" && phone != "") {
      try {
        emailjs
          .sendForm(
            "service_rpmt5hw",
            "template_1ch6r97",
            form.current,
            "LcUxZpGcSFrWWHjHw"
          )
          .then(
            (result) => {
              alert("Message sent !");
              document.getElementById("form").reset();
              console.log(result.text);
              setLoading(false);
              setOpen(false);
            },
            (error) => {
              setLoading(false);
              console.log(error.text);
              setOpen(false);
            }
          );
      } catch (err) {
        alert(err);
      }
    } else {
      setLoading(false);
      alert("Please fill out all details !");
    }
  };

  return (
    <Container className="">
      {props.txtbn == true ? (
        <center>
          <button
            className="btn btn-outline-warning  w-75 mb-3 mt-3"
            onClick={() => setOpen(!open)}
          >
            <b>Contact us</b>
          </button>
        </center>
      ) : (
        <Zoom up>
          <button className="fbn" onClick={() => setOpen(!open)}>
            <img src={Chat} className="msg-icon" />
          </button>
        </Zoom>
      )}
      <center>
        <Popup modal open={open} position="right center">
          <center>
            <div id="contactus" className="contactus p-4">
              <Row>
                <Col md={10} lg={10} xs={10}>
                  <h4
                    style={{ marginLeft: 50, marginBottom: 20, color: "#08f" }}
                  >
                    Get Free Consultation
                  </h4>
                </Col>
                <Col md={2} lg={2} xs={2}>
                  <button className="cross m-1" onClick={() => setOpen(!open)}>
                    X
                  </button>
                </Col>
              </Row>
              <Form ref={form} onSubmit={sendEmail} id="form">
                <input
                  type={"text"}
                  placeholder={"Your Name"}
                  className="form-control"
                  name="name"
                />

                <input
                  type={"text"}
                  placeholder={"Your Phone Number"}
                  className="form-control mt-2"
                  name="phone"
                />

                <input
                  type={"text"}
                  placeholder={"Your Email"}
                  className="form-control mt-2"
                  name="email"
                />

                <textarea
                  type={"text"}
                  placeholder={"Your Message"}
                  className="form-control mt-2 mb-2"
                  style={{ height: 100 }}
                  name="message"
                />
                {loading == true ? (
                  <Spinner animation="border" variant="primary" />
                ) : (
                  <button className="btn btn-primary smbn w-100" type="submit">
                    Get a call back
                  </button>
                )}
              </Form>
            </div>
          </center>
        </Popup>
      </center>
    </Container>
  );
};

export default ContactUs;
