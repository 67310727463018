import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import FixedBar from "../../components/FixedBar";
import HomeBn from "../../components/HomeBn";
import "../../services.css";
import Img from "../../assets/services/services.jpg";
import Footer from "../../components/Footer";
import Fade from "react-reveal/Fade";
import ContactUs from "../../components/ContactUs";
import WhatsApp from "../../components/Whatsapp";

const Outsourcing = () => {
  return (
    <div className="p-2 serv">
      <FixedBar home={true} />
      <Fade>
        <img src={Img} className="w-100 rounded mb-4 svimg" />
      </Fade>
      <Container>
        <Row>
          <h2 className="svhead">SERVICES PROCESS OUTSOURCING (SPO)</h2>
        </Row>
        <div className="sline" />
        <p className="justify">
          In today’s complex business arena, it becomes quite a challenge for
          business enterprises to supervise all their audits, accounts and
          finance management on their own. Most entrepreneurs, business owners
          and management of companies are good at delivering their goods or
          services as well as in customer relationship management. However,
          support functions such as Finance, finance, taxation, accounts and
          payroll waste company’s time and cost. Consequently, this is where we
          step in
          <br /> <br />
          Our Service Process Outsourcing unit (SPO) help organizations offload
          Finance process in full or part to us.The functional leadership team
          has years of experience in multinational corporations and comes from
          diverse background.
          <br /> <br />
          As Chartered Accountants, we help small and medium enterprises or
          large companies in managing their finance, taxation, accounts and
          payroll processing as well as assist them in utilizing their resources
          to the maximum extent.
          <br />
        </p>
        <h5 className="bold">
          Our Service process outsourcing areas comprise of
        </h5>
        <br />
        <ul>
          <li>
            <b>Payroll Outsourcing :</b> Payroll outsourcing primarily guides
            companies to file tax returns on time thus relieving the pressure of
            work, narrowing the load and helping them get more systematic with
            their core business. We simplify your complications by offering
            highly comprehensive and quality payroll processing services.
          </li>
          <li>
            <b>Accounts Outsourcing : </b>Our key focus areas are to cater
            transformational value to our client’s viz., process
            excellence-automation, re-engineering, internal/ external
            benchmarking and variable pricing models.
          </li>
          <li>
            <b>Knowledge Process Outsourcing (KPO) : </b> We support
            organizations pursuing innovative solutions and services to achieve
            operational competitiveness. Backed by an
            integrated IT-KPO approach, we focus on supplying evolutional value
            to our clients, beyond managing SLAs.
          </li>
        </ul>
        <br />
        <h5 className="blue-color">
          Our expert will help you with Get a free consultation today.
        </h5>
        <br />
        <h5>FAQs </h5>
        <br /> <b>What is Service process outsourcing (SPO)?</b>
        <br />
        <br />
        <p className="justify">
          Service process outsourcing (SPO) includes contracting a third party
          to provide specific business functions. Various operational processes
          are necessary to run a business; but, recruiting a permanent employee
          to manage each process can be costly. Business process outsourcing
          permits a business owner to utilize experts to handle these duties
          without the costs associated with hiring permanent staff members.
          <br /> <br />
        </p>
        <b> How does outsourcing benefit my business?</b> <br />
        <br />
        <p className="justify">
          Outsourcing will not only save your money but time as well – time that
          can be utilized to focus on growing and improving your business. For
          example, a corporate executive’s time is best spent developing
          strategies in core business areas, not agonizing about the day-to-day
          activities that keep the company running.
        </p>
        <b>How does outsourcing reduce business costs?</b> <br />
        <br />
        <p className="justify">
          Outsourcing will lower down the labor costs, operational costs and
          technology costs. It abolishes the budget that would be mandatory for
          recruitment and training. It also reduces the need to invest in office
          space, furniture, equipment, human resource functions and technology.
          The cost savings can enable a business to maintain lower rates, which
          will upgrade its competitive advantage in the market.
        </p>
        <b>What services do you offer under business process outsourcing?</b>{" "}
        <br />
        <br />
        <p className="justify">
          At Bizcare , our team of experts will guide you through preparing
          Management Information System (MIS) reports and outsource accounting
          functions through bookkeeping & accounting, payroll, accounts payable,
          AR follow-up – Aging analysis and reports, bank/credit card
          reconciliations, P&L, balance sheet and other regular reports,
          preparation of tax returns and regulatory compliance
        </p>
      </Container>
      <ContactUs />
      <WhatsApp />
      <Footer />
    </div>
  );
};

export default Outsourcing;
