import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import FixedBar from "../../components/FixedBar";
import HomeBn from "../../components/HomeBn";
import "../../services.css";
import Img from "../../assets/services/tax.jpg";
import Footer from "../../components/Footer";
import Fade from "react-reveal/Fade";
import ContactUs from "../../components/ContactUs";
import WhatsApp from "../../components/Whatsapp";

const Taxation = () => {
  return (
    <div className="p-2 serv">
      <FixedBar home={true} />
      <Fade>
        <img src={Img} className="w-100 rounded mb-4 svimg" />
      </Fade>
      <Container>
        <Row>
          <h2 className="svhead">TAXATION AND ZAKAT ADVISORY SERVICES</h2>
        </Row>

        <div className="sline" />
        <ol>
          <li>GST SERVICES</li>
          <p>Panacea to all your GST issues.</p>

          <p className="justify">
            <b>The Goods and Services Tax (GST)</b> has consolidated a plethora
            of indirect taxes levied by the Centre and states into a common
            tax. It eradicated multiplicity of taxes thereby reducing the
            complexity and removing the cascading effect of taxes. Present taxes
            levied on the sale of goods or services by either Central or State
            Government are embraced under the GST regime. Goods and Services Tax
            came into effect on July 1, 2017. Businesses are required to file
            monthly, quarterly and annual returns and those with turnover
            exceeding INR 2 crore will also have to file audit reports.
          </p>
          <p className="justify">
            <b> Our portfolio of services</b>, that makes it easy for you to
            comply with this tax regime, comprises of:
          </p>
          <ol>
            <li>GST Registration: </li>
            <p className="justify">
              GST registration process under the new GST rules is done through
              an online portal that is maintained by the Central Government of
              India. Our experts can get your GST registration done in a short
              span of time.
            </p>

            <li>GST Returns:  </li>
            <p className="justify">
              GST Act mandates every registered entity to furnish details of its
              sales and purchases including tax paid and collected thereon by
              filing GST returns Taxpayers must provide correct information to
              department as the system is designed so that all transactions are
              in synchronized with each other and no transaction is left
              unnoticed between buyers and sellers. GST team of AT BIZCARE
              let you focus on your business, by filing your tax returns in
              compliance with GST regime.
            </p>
            <li>GST Amendments:</li>
            <p className="justify">
               Once you are registered as goods and services taxpayer and want
              to make any alteration in your details of core or non-core fields
              like change in address, addition of place of business etc., you
              may reach our GST experts for any assistance.
            </p>
            <li>GST Audits:</li>
            <p className="justify">
               Our team of professionals with rich knowledge on GST,
              perform audits and prepares required audit reports for its
              clients.
            </p>
            <li>GST Assessment: </li>
            <p className="justify">
              We can help you with your self-assessment of GST. Our team will
              assist you in whole process of assessment carried out by tax
              authorities.
            </p>
            <li>GST Compliances: </li>
            <p className="justify">
              We will keep you updated with any new compliances announced by GST
              Council.
            </p>
            <li>GST REFUND SERVICES</li>
            <p className="justify">
              As per the provisions included under IGST law, the exports of
              services are considered as zero-rated supply is exempted from GST
              and are eligible to claim the refund of GST paid. The GST portal
              has been set up for making GST refund claims efficiently. In this
              article, we look at the process for claiming GST refund on exports
              of services.
            </p>
          </ol>
          <li>INCOME (DIRECT) TAX</li>
          <i>
            We promise to guide you for all your taxation essentials with highly
            experienced & seasoned tax Professionals having in-depth knowledge
            of Indian and international tax laws
          </i>
          <br />
          <br />
          <p className="justify">
            As a chartered accountancy firm, we aim at guiding you throughout
            your tax compliances. Taxation is a specialized area and requires
            great skill and accuracy. Our experts have the requisite knowledge
            to carry out tax planning, administration, documentation and
            representation.
          </p>
          <ol>
            <li>DOMESTIC TAXATION SERVICES</li>
            <p className="justify">
              In the realm of taxation services we are catering to large
              multinational enterprises and domestic business houses. Our focus
              is on developing as well as planning compliance tools and
              strategies that compliment your business. Our team is also
              proficient in handling assessment and representations.
            </p>
            <p className="justify">
              <b> Direct Tax:</b> With tax laws progressing each day, it is
              crucial for businesses to be cognizant with timely compliance
              challenges that may pose a threat and lead to penalties and
              prosecution. A tax efficient structure for the businesses will
              release burden from entity’s shoulders and thereby ensure
              pertinent compliances. Our service portfolio includes advisory and
              compliance services w.r.t. income tax, advance tax, tax deducted
              at source and wealth tax.
            </p>
            <p className="justify">
              <b>Corporate Tax:</b> Corporate tax is a tax levied on profits
              earned by various entities. Companies registered under Companies
              Act or any other act for the time being in force are obligated to
              pay such tax at a rate specified by Income Tax Act, subject to
              changes in rates from time-to-time. We assist organizations in
              corporate tax returns filing, advising on tax planning
              opportunities, tax compliances, opinions and representations. We
              also provide opinion on complex tax issues and assist in review of
              tax strategy vis-a-vis business strategy.
            </p>
            <p className="justify">
              <b>Personal Tax:</b> Apart from tax paid on business earnings, tax
              paid on one’s personal income is termed as personal tax. We manage
              tax computation for tax withholding, advance tax and tax filings.
              Our team is experienced in representations before revenue
              authorities. In our NRI desk, we also provide assistance in
              obtaining various registrations for non-residents such as
              registration with foreign regional registration office (FRRO),
              permanent account number (PAN) etc.
            </p>
            <p className="justify">
              <b>Tax assessment, representation and litigations:</b> Examination
              of tax returns by a tax department, if it has any reason to
              believe that the information furnished by the assessee is
              incomplete or incorrect is considered for scrutiny Assessment. A
              notice is issued in this respect in the name of assessee and is
              expected to take action as per the directions by the tax
              department.
            </p>
            <br />
            <b>OUR SERVICES INCLUDE:</b>
            <ol className="black-list">
              <li>Income tax Return Filing </li>
              <li>Income tax scrutiny assessment</li>
              <li>Tax Audits</li>
              <li>
                Representation before company law board, registrar of companies
                and regional director
              </li>
              <li>Excise assessment and litigations</li>
              <li>Income tax appeal matters and litigations</li>
              <li>
                Representation before Reserve Bank of India (RBI) / Foreign
                Exchange Management Act (FEMA)
              </li>
            </ol>
            <li>INTERNATIONAL TAXATION SERVICES </li>
            <p className="justify">
               Several multinational enterprises have entered the boundaries of
              India resulting in colossal influx of foreign direct investment
              (FDI) in India. Also, since the advent of liberalization, Indian
              entities have made overseas direct investment in diversified
              countries to capture global opportunities. We provide advisory
              services for corporate structuring and planning, Double tax relief
              and tax treaties interpretation, outbound and inbound investments,
              compliance related to foreign remittances, transfer pricing and
              representation in assessments.
            </p>
            <p className="jusstify">
              <b>Transfer pricing:</b> The price paid for transfer of goods or
              services from one entity to another situated in divergent
              countries with prescribed exceptions. Such value attached to goods
              or services transferred amongst related parties is termed as
              transfer pricing. We offer effective solutions to companies which
              undertake international transactions or specified domestic
              transactions with its associated enterprise or group companies.
            </p>
            <b>OUR SERVICES INCLUDE:</b>
            <ol className="black-list">
              <li>Transfer pricing analysis and tax structuring</li>
              <li>Benchmarking analysis</li>
              <li>Preparation of transfer pricing documentation</li>
              <li>Issuance of Transfer Pricing Certificate</li>
              <li>Representation for transfer pricing assessments</li>
            </ol>
          </ol>
          <li>EXPAT TAXATION SERVICES:</li>
          <p className="justify">
            Tax is one of our specializations and if you are an expat with a
            financial question, we are here to assist you for the same. Our tax
            experts are proficient in handling all the tax compliances for Expat
            taxation and have requisite experience in this domain.
          </p>
          <p className="justify">
            Our expatriate services experts advise you and your employees on tax
            related issues that come into existence as a result of employee
            mobility. Repatriation assistance for employees, tax compliances,
            tax return preparation, annual tax equalisation calculations,
            payroll processing services also form part of services provided by
            us.
          </p>
          <li>ZAKAT CONSULTANCY </li>
          <p className="justify">
            Zakat is an amount arrived at by assessment of a person's wealth, to
            be used to support the poor, aged, and debtors in the ummah (Islamic
            world).
          </p>
          <p className="justify">
            In some cases, this assessment may be increased, depending on the
            wealth of the person, and the means by which they acquire their
            wealth.
          </p>
          <p className="justify">
            Bizcare. assists our clients in this regard with the provision
            of services relating to the calculation of Zakat on funds relating
            to institutions, companies and the individual.
          </p>
          <li> Tax Residency Certificate</li>
          <p className="justify">
            A Tax Residency Certificate is a certificate issued for eligible
            natural and legal persons to take advantage of agreements of double
            taxation avoidance of income signed by the UAE.
          </p>
          <p className="justify">
            The Tax Residency Certificate is open to any company operating on
            the mainland or in a Free zone that has been active in the country
            for at least a year. Offshore companies are ineligible for this and
            must receive a tax exemption certificate instead of the Tax
            Residency Certificate.
          </p>
          <p className="justify">
            As well as business entities, the Tax Residency Certificate is also
            available to individuals who have resided in the UAE for at least
            180 days and who wish to establish tax residency in the country.
            This is particularly beneficial for individuals whose native
            countries do not have a double taxation agreement with the UAE. The
            individuals must have a valid UAE resident visa for more than 180
            days to apply.
          </p>
          <p className="justify blue-color">
            If you need assistance to obtain a Tax Residency Certificate as an
            individual or a company in the UAE, please get in touch with us.
          </p>
        </ol>
      </Container>
      <ContactUs />
      <WhatsApp />
      <Footer />
    </div>
  );
};

export default Taxation;
