import { collection, doc, onSnapshot } from "firebase/firestore";
import firebase from "../../firebase";
import React, { Component, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import "./login.css";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [logined, setLogin] = useState(false);
  const navigate = useNavigate();

  const Signin = async () => {
    console.log(email);
    if (email == "" || password == "") {
      alert("Please fill out  all fields !");
    } else {
      try {
        var result = firebase
          .auth()
          .signInWithEmailAndPassword(email, password);
        await result;
        var uid = firebase.auth().currentUser.uid;
        if (uid == "VadXQ0xpDDMVmD3F27SDDZQSY2B3") {
          setLogin(true);
          navigate("/Add_blogs");
        } else {
          alert("Invalid login !");
        }
      } catch (err) {
        alert(err);
      }
    }
  };

  return (
    <body className="body">
      <div className="container0">
        <div className="brand-logo"></div>
        <div className="brand-title">Admin</div>
        <div className="inputs">
          <label className="lbl">EMAIL</label>
          <input
            type="email"
            className="inp"
            placeholder="example@test.com"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
          <label className="lbl">PASSWORD</label>
          <input
            type="password"
            placeholder="type your password..."
            className="inp"
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
          <button className="bluebn mt-3" onClick={() => Signin()}>
            LOGIN
          </button>
        </div>
      </div>
    </body>
  );
};

export default Login;
