import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/firestore";

const config = {
  apiKey: "AIzaSyDcmz2VtJekuCBLK-rkmUZ1raPwN-4bvSk",
  authDomain: "bizcare-17958.firebaseapp.com",
  projectId: "bizcare-17958",
  storageBucket: "bizcare-17958.appspot.com",
  messagingSenderId: "653639525537",
  appId: "1:653639525537:web:9395ae89b2a795cc16bce0",
  measurementId: "G-Y5W6K0BYSW",
};
firebase.initializeApp(config);

export default firebase;
