import React from "react";
import {Col, Container, Row} from "react-bootstrap";

import Team1 from "../../assets/team1.jpg";
import Team2 from "../../assets/team2.jpg";
import CEOImg from "../../assets/ceo.jpg";
import Team3 from "../../assets/team3.jpg";

import cartoon from "../../assets/cartoon-contact.png";
import ContactUs from "../ContactUs";
import "./ceo.css";
const CEO = () => {
    return (
        <div className="container">
            <div className="ceocard">
                <Row>

                    <div className="ceo p-5">
                        <div className="al-left">
                            <center>
                                <h2>OUR TEAM</h2>
                                <div className="line mb-4 mt-3"/>
                            </center>

                        </div>
                        <Row>

                            <Col md={6}
                                lg={3}
                                xs={12}>
                                <div className="bl-1">
                                    <center>
                                        <img src={Team1}
                                            className="mt-2 mb-3 teamimg"/>
                                        <span className="bgblue">
                                            <h4>MUHAMMED JUBAID K
                                            </h4>
                                            <span style={{fontSize:12}}>CO FOUNDER</span><br/>
                                            <i>MANAGING DIRECTOR</i>
                                        </span>
                                    </center>
                                </div>


                            </Col>

                            <Col md={6}
                                lg={3}
                                xs={12}>
                                <div className="bl-1">
                                    <center>
                                        <img src={Team2}
                                            className=" mt-2 mb-3 teamimg"/>
                                        <span className="bgblue">
                                            <h4>IRFANA HUSSNA M
                                            </h4>
                                            <span style={{fontSize:12}}>CO FOUNDER</span><br/>
                                            <i>CUSTOMER RELATIONSHIP MANAGER</i>
                                        </span>
                                    </center>
                                </div>
                            </Col>

                            <Col md={6}
                                lg={3}
                                xs={12}>
                                <div className="bl-1">
                                    <center>
                                        <img src={CEOImg}
                                            className=" mt-2 mb-3 teamimg"/>
                                        <span className="bgblue">
                                            <h4>CA MARSOOQ
                                            </h4>
                                            <i>DIRECTOR</i>
                                        </span>
                                    </center>
                                </div>
                            </Col>


                            <Col md={6}
                                lg={3}
                                xs={12}>
                                <div className="bl-1">
                                    <center>
                                        <img src={Team3}
                                            className=" mt-2 mb-3 teamimg"/>
                                        <span className="bgblue">
                                            <h4>MOHAMMED FAIS</h4>
                                            <i>AUDIT MANAGER</i>
                                        </span>
                                    </center>
                                </div>
                            </Col>

                        </Row>

                    </div>


                </Row>
            </div>
        </div>
    );
};

export default CEO;
